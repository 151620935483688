<template>
	<div>
		<wd-navbar>
			<div slot="left" class="middle" @click="$goBack">
				<wd-icon name="arrow-left" />
			</div>
			<div>
				<span>实时控制</span>
			</div>
		</wd-navbar>
		<div>
			<span class="stats">开关状态：<span v-if="equipInfo.equipmentStatus == '开'" style="color: #34d19d">{{equipInfo.equipmentStatus || '--'}}</span><span v-else-if="equipInfo.equipmentStatus == '关'" style="color: #fa4350">{{equipInfo.equipmentStatus || '--'}}</span><span v-else>{{equipInfo.equipmentStatus || '--'}}</span></span>
			<div class="top">
				<img v-if="equipInfo.equipmentType == '0'" src="../../assets/img/ele.svg" style="max-width: 20vw" alt="" />
				<img v-else-if="equipInfo.equipmentType == '1'" src="../../assets/img/water.svg" style="max-width: 20vw" alt="" />
			</div>
			<wd-divider style="margin-top: 15px">设备信息</wd-divider>
			<ul class="info">
				<li>仪表名称：{{equipInfo.equipmentName || '--'}}</li>
				<li>表计编码：{{equipInfo.addr || '--'}}</li>
				<li>安装位置：{{equipInfo.installationSite || '--'}}</li>
				<li>账户编码：{{equipInfo.acctId || '--'}}</li>
			</ul>
			<wd-divider style="margin-top: 15px">设备计量</wd-divider>
			<div class="listItem">
				<div class="list">
					<div class="item">
						<span class="cbss">{{equipInfo.equipmentCurrentLarge || '--'}}</span>
						<span><img src="../../assets/img/count.svg" style="max-width: 45px" alt="" /></span>
						<span>抄表示数</span>
					</div>
					<div class="item">
						<span class="syje">{{equipInfo.remainingBalance || '--'}}</span>
						<span><img src="../../assets/img/usage.svg" style="max-width: 45px" alt="" /></span>
						<span>剩余金额</span>
					</div>
				</div>
				<div class="list" style="margin-top: 15px">
					<div class="item">
						<span class="cbss">{{equipInfo.equipmentLatestLarge || '--'}}</span>
						<span><img src="../../assets/img/count.svg" style="max-width: 45px" alt="" /></span>
						<span>结算示数</span>
					</div>
					<div class="item">
						<span class="cbsj" style="white-space: nowrap; font-size: 14px">{{equipInfo.currentDealDate || '--'}}</span>
						<span><img src="../../assets/img/time.svg" style="max-width: 45px" alt="" /></span>
						<span>结算时间</span>
					</div>
				</div>
			</div>
			<div class="btnItem">
				<wd-button class="btn" @click="readSpread">抄表</wd-button>
				<wd-button class="btn" @click="getInfo(1)">读取开关状态</wd-button>
				<wd-button class="btn" @click="openAndClose('1')">开</wd-button>
				<wd-button class="btn" @click="openAndClose('0')">关</wd-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				equipInfo: {},
				queryParams: {
					pageNum: 1,
					pageSize: 15,
					id: null,
					roleKey: null
				}
			}
		},
		created() {
			this.queryParams.roleKey = localStorage.getItem('roleKey')
			this.equipInfo = this.$route.params
			this.queryParams.id = this.equipInfo.id
			if( !this.equipInfo.id ) {
				this.$router.push("/control")
			} else {
				this.getInfo()
			}
		},
		methods:{
			readSpread(){
				this.$http.post("/external/equipment/reading", {id: this.equipInfo.id}).then(res=>{
					if( res.code == 200 ) {
						this.$toast.success("抄表成功！")
						this.getInfo()
					} else {
						this.$toast.error(res.msg)
					}
				})
			},
			getInfo(type=0){
				this.$http.get("/external/equipment/equipment/list", { params: this.queryParams }).then(res=>{
					if( res.code == 200 ) {
						this.equipInfo = res.rows[0]
						if( type == 1 ) {
							this.$toast.success("读取成功！")
						}
					}
				})
			},
			openAndClose(cmd){
				let text = cmd == "0" ? "关闭" : "开启"
				this.$messageBox.confirm('是否'+text+'设备', '提示').then(() => {
					this.$http.post("/external/equipment/control", { id: this.equipInfo.id, controlType: cmd, controlComment: 'C0001', businessId: new Date().getTime() }).then(res=>{
						if( res.code == 200 ) {
							if( cmd == "1" ) {
								this.$toast.success("开闸成功！")
							} else {
								this.$toast.success("关闸成功！")
							}
						} else {
							this.$toast.error(res.msg)
						}
					})
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.stats{
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-right: 20px;
		font-size: 15px;
		color: #666666;
	}
	.top{
		margin-top: 10px;
		width: 100vw;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.info{
		margin: 0;
		margin-left: 15px;
		margin-top: 10px;
		padding: 0;
		li{
			list-style: none;
			margin-top: 5px;
			font-size: 14px;
			color: #666666;
		}
	}
	.listItem{
		margin: 15px;
		width: calc(100vw - 30px);
		.list{
			width: 100%;
			display: flex;
			justify-content: space-between;
			.item{
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				font-size: 14px;
				span{
					&:not(:last-child){
						margin-top: 10px;
					}
					&.cbss{
						color: #F56C6C;
						font-size: 16px;
					}
					&.syje{
						color: #67C23A;
						font-size: 16px;
					}
					&.cbsj{
						color: #606266;
						font-size: 16px;
					}
				}
			}
		}
	}
	.btnItem{
		display: flex;
		align-items: center;
		justify-content: space-around;
		flex-wrap: wrap;
		.btn{
			width: 40%;
			margin-top: 10px;
		}
	}
</style>
